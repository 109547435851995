import React, { useState, useEffect } from 'react';
import { BrowserRouter , Route, Switch } from 'react-router-dom';

import start from './components/startpage'
import main from './components/main'
import videopage from './components/videopage'


const App = () => {

    
    return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={start} />
        <Route path="/main" exact component={main} />
        <Route path="/poson-kudu" exact component={videopage} />
      
      </Switch>
    </BrowserRouter>
    );
};

export default App;